import img1 from "../../images/products/img_1.jpg";
import img2 from "../../images/products/img_2.jpg";
import img3 from "../../images/products/img_3.jpg";
import img4 from "../../images/products/img_4.jpg";
import img5 from "../../images/products/img_5.jpg";
import img6 from "../../images/products/img_6.jpg";
import img7 from "../../images/products/img_7.jpg";
import img8 from "../../images/products/img_8.jpg";
import img9 from "../../images/products/img_9.jpg";
import img10 from "../../images/products/img_10.jpg";
import img11 from "../../images/products/img_11.jpg";
import img12 from "../../images/products/img_12.jpg";
import img13 from "../../images/products/img_13.jpg";
import img14 from "../../images/products/img_14.jpg";
import img15 from "../../images/products/img_15.jpg";
import img16 from "../../images/products/img_16.jpg";
import img17 from "../../images/products/img_17.jpg";
import img18 from "../../images/products/img_18.jpg";
import img19 from "../../images/products/img_19.jpg";
import img20 from "../../images/products/img_20.jpg";
import img21 from "../../images/products/img_21.jpg";
import img22 from "../../images/products/img_22.jpg";
import img23 from "../../images/products/img_23.jpg";
import img24 from "../../images/products/img_24.jpg";
import img25 from "../../images/products/img_25.jpg";
import img26 from "../../images/products/img_26.jpg";
import img27 from "../../images/products/img_27.jpg";
import img28 from "../../images/products/img_28.jpg";
import img29 from "../../images/products/img_29.jpg";
import img30 from "../../images/products/img_30.jpg";
import img31 from "../../images/products/img_31.jpg";
import img32 from "../../images/products/img_32.jpg";
import img33 from "../../images/products/img_33.jpg";
import img34 from "../../images/products/img_34.png";
import img35 from "../../images/products/img_35.png";
import img36 from "../../images/products/img_36.png";
import img37 from "../../images/products/img_37.png";
import img38 from "../../images/products/img_38.png";
import img39 from "../../images/products/img_39.png";
import img40 from "../../images/products/img_40.png";
import img41 from "../../images/products/img_41.png";
import img42 from "../../images/products/img_42.png";
import img43 from "../../images/products/img_43.png";
import img44 from "../../images/products/img_44.png";
import img45 from "../../images/products/img_45.png";
import img46 from "../../images/products/img_46.png";
import img47 from "../../images/products/img_47.png";
import img48 from "../../images/products/img_48.png";
// import img49 from "../../images/products/img_51.jpg";
import img50 from "../../images/products/img_52.jpg";
import img51 from "../../images/products/img_53.jpg";
import img52 from "../../images/products/img_54.jpg";
import img53 from "../../images/products/img_55.jpg";
import img54 from "../../images/products/img_56.jpg";
import img55 from "../../images/products/img_57.jpg";
import img56 from "../../images/products/img_58.jpg";
import img57 from "../../images/products/img_59.jpg";
import img58 from "../../images/products/img_60.jpg";
import img59 from "../../images/products/img_61.jpg";
// New ones
import img60 from "../../images/products/img_62.jpg";
import img61 from "../../images/products/img_63.jpg";
import img62 from "../../images/products/img_64.jpg";
import img63 from "../../images/products/img_65.jpg";
import img64 from "../../images/products/img_66.jpg";
import img65 from "../../images/products/img_67.jpg";
import img66 from "../../images/products/img_68.jpg";
import img67 from "../../images/products/img_69.jpg";
import img68 from "../../images/products/img_70.jpg";
import img69 from "../../images/products/img_71.jpg";
import img70 from "../../images/products/img_72.jpg";
import img71 from "../../images/products/img_73.jpg";
import img72 from "../../images/products/img_74.jpg";
import img73 from "../../images/products/img_75.jpg";
import img74 from "../../images/products/img_76.jpg";
import img75 from "../../images/products/img_77.jpg";
import img76 from "../../images/products/img_78.jpg";
import img77 from "../../images/products/img_79.jpg";
import img78 from "../../images/products/img_80.jpg";
import img79 from "../../images/products/img_81.jpg";
import img80 from "../../images/products/img_82.jpg";
import img81 from "../../images/products/img_83.jpg";
import img82 from "../../images/products/img_84.jpg";
import img83 from "../../images/products/img_85.jpg";
import img84 from "../../images/products/img_86.jpg";
import img85 from "../../images/products/img_87.jpg";
import img86 from "../../images/products/img_88.jpg";
import img87 from "../../images/products/img_89.jpg";
import img88 from "../../images/products/img_90.jpg";
import img89 from "../../images/products/img_91.jpg";
import img90 from "../../images/products/img_92.jpeg";
import img91 from "../../images/products/img_93.jpeg";
import img92 from "../../images/products/img_94.jpeg";
import img93 from "../../images/products/img_95.jpeg";
import img94 from "../../images/products/img_96.jpeg";
import img95 from "../../images/products/img_97.jpeg";
import img96 from "../../images/products/img_98.jpeg";
import img97 from "../../images/products/img_99.jpeg";
import img98 from "../../images/products/img_100.jpeg";
import img99 from "../../images/products/img_101.jpeg";
import img100 from "../../images/products/img_102.jpeg";
import img101 from "../../images/products/img_103.jpeg";
import img102 from "../../images/products/img_104.jpeg";
import img103 from "../../images/products/img_105.jpeg";
import img104 from "../../images/products/img_106.jpeg";
import img105 from "../../images/products/img_107.jpeg";
import img106 from "../../images/products/img_108.jpeg";
import img107 from "../../images/products/img_109.jpeg";
import img108 from "../../images/products/img_110.jpeg";
import img109 from "../../images/products/img_111.jpeg";
import img110 from "../../images/products/img_112.jpeg";
import img111 from "../../images/products/img_113.jpeg";
import img112 from "../../images/products/img_114.jpeg";
import img113 from "../../images/products/img_115.jpeg";
import img114 from "../../images/products/img_116.jpeg";
import img115 from "../../images/products/img_117.jpeg";
import img116 from "../../images/products/img_118.jpeg";
import img117 from "../../images/products/img_119.jpeg";
import img118 from "../../images/products/img_120.jpeg";

export const allProducts = [
  // Paintings 1
  {
    id: 1,
    category: "Painting",
    image: img1,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "John Israel G.",
    subCategory: "Mixed Media",
  },

  {
    id: 2,
    category: "Painting",
    image: img2,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "Seun Odeyemi",
    subCategory: "Figurative",
  },

  {
    id: 3,
    category: "Painting",
    image: img3,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "Aragbada Stephen",
    subCategory: "Figurative",
  },

  {
    id: 4,
    category: "Painting",
    image: img4,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "Adewunmi James",
    subCategory: "Abstract",
  },

  {
    id: 5,
    category: "Painting",
    image: img5,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "John Israel G.",
    subCategory: "Mixed Media",
  },

  {
    id: 6,
    category: "Painting",
    image: img6,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "Tolawewe",
    subCategory: "Abstract",
  },

  {
    id: 7,
    category: "Painting",
    image: img7,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "John Israel G.",
    subCategory: "Mixed Media",
  },

  {
    id: 8,
    category: "Painting",
    image: img8,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "Aragbada Stephen",
    subCategory: "Figurative",
  },

  {
    id: 9,
    category: "Painting",
    image: img9,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "John Israel G.",
    subCategory: "Landscape",
  },

  {
    id: 10,
    category: "Painting",
    image: img10,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "Gbelekale Azeez",
    subCategory: "Impressionism",
  },

  {
    id: 11,
    category: "Painting",
    image: img11,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur.",
    author: "Adewunmi James",
    subCategory: "Abstract",
  },

  {
    id: 12,
    category: "Painting",
    image: img12,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur (III)",
    author: "Adewunmi James",
    subCategory: "Abstract",
  },

  {
    id: 13,
    category: "Painting",
    image: img13,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur (II)",
    author: "Adewunmi James",
    subCategory: "Abstract",
  },

  {
    id: 14,
    category: "Painting",
    image: img14,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Seun Odeyemi",
    subCategory: "Figurative",
  },

  {
    id: 15,
    category: "Painting",
    image: img15,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Adewunmi James",
    subCategory: "Abstract",
  },

  {
    id: 16,
    category: "Drawing",
    image: img16,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Marcus Maxwel",
    subCategory: "Charcoal",
  },

  {
    id: 17,
    category: "Painting",
    image: img17,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Seun Odeyemi",
    subCategory: "Figurative",
  },

  {
    id: 18,
    category: "Painting",
    image: img18,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "John Israel G.",
    subCategory: "Abstract",
  },

  {
    id: 19,
    category: "Painting",
    image: img19,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "John Israel G.",
    subCategory: "Mixed Media",
  },

  {
    id: 20,
    category: "Painting",
    image: img20,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur (I)",
    author: "Gbelekale Azeez",
    subCategory: "Impressionism",
  },

  {
    id: 21,
    category: "Painting",
    image: img21,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Adewunmi James",
    subCategory: "Abstract",
  },

  {
    id: 22,
    category: "Painting",
    image: img22,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "John Israel G.",
    subCategory: "Landscape",
  },

  {
    id: 23,
    category: "Painting",
    image: img23,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Gbelekale Azeez",
    subCategory: "Impressionism",
  },

  {
    id: 24,
    category: "Painting",
    image: img24,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Gbelekale Azeez",
    subCategory: "Impressionism",
  },

  {
    id: 25,
    category: "Painting",
    image: img25,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Gbelekale Azeez",
    subCategory: "Impressionism",
  },

  {
    id: 26,
    category: "Painting",
    image: img26,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Aragbada Stephen",
    subCategory: "Figurative",
  },

  {
    id: 27,
    category: "Painting",
    image: img27,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Tolawewe",
    subCategory: "Abstract",
  },

  {
    id: 28,
    category: "Painting",
    image: img28,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur (I)",
    author: "Adewunmi James",
    subCategory: "Abstract",
  },

  {
    id: 29,
    category: "Painting",
    image: img29,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Tolawewe",
    subCategory: "Abstract",
  },

  {
    id: 30,
    category: "Painting",
    image: img30,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Tolawewe",
    subCategory: "Abstract",
  },

  {
    id: 31,
    category: "Painting",
    image: img31,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Aragbada Stephen",
    subCategory: "Figurative",
  },

  {
    id: 32,
    category: "Painting",
    image: img32,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "John Israel G.",
    subCategory: "Landscape",
  },

  {
    id: 33,
    category: "Painting",
    image: img33,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Adewunmi James",
    subCategory: "Abstract",
  },

  {
    id: 34,
    category: "Painting",
    image: img34,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Tolawewe",
    subCategory: "Abstract",
  },

  {
    id: 35,
    category: "Painting",
    image: img35,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Seun Odeyemi",
    subCategory: "Landscape",
  },

  {
    id: 36,
    category: "Painting",
    image: img36,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Tolawewe",
    subCategory: "Abstract",
  },

  {
    id: 37,
    category: "Painting",
    image: img37,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Tolawewe",
    subCategory: "Abstract",
  },

  {
    id: 38,
    category: "Painting",
    image: img38,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Gbelekale Azeez",
    subCategory: "Impressionism",
  },

  // Sculpture

  {
    id: 39,
    category: "Sculpture",
    image: img39,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "B. Alvin",
    subCategory: "Metal",
  },

  {
    id: 40,
    category: "Sculpture",
    image: img40,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Marcus Maxwel",
    subCategory: "Metal",
  },

  {
    id: 41,
    category: "Sculpture",
    image: img41,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Akintomide Adesemoye",
    subCategory: "Metal",
  },

  {
    id: 42,
    category: "Sculpture",
    image: img42,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Kenny Adamson",
    subCategory: "Wood-work",
  },

  {
    id: 43,
    category: "Sculpture",
    image: img43,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Adewunmi James",
    subCategory: "Metal",
  },

  {
    id: 44,
    category: "Sculpture",
    image: img44,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Ojegoke Pelumi",
    subCategory: "Wood-work",
  },

  {
    id: 45,
    category: "Sculpture",
    image: img45,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Adewunmi James",
    subCategory: "Metal",
  },

  {
    id: 46,
    category: "Sculpture",
    image: img46,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Ojegoke Pelumi",
    subCategory: "Metal",
  },

  {
    id: 47,
    category: "Sculpture",
    image: img47,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Adewunmi James",
    subCategory: "Metal",
  },

  {
    id: 48,
    category: "Sculpture",
    image: img48,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Kenny Adamson",
    subCategory: "Fibre Glass",
  },

  // {
  //   id: 49,
  //   category: "Sculpture",
  //   image: img49,
  //   title: "ADAO - Lorem ipsum dolor sit amet consectetur",
  //   author: "Lorem Ipsum",
  //   subCategory: "Wood-work",
  // },

  {
    id: 50,
    category: "Sculpture",
    image: img50,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Adewunmi James",
    subCategory: "Metal",
  },

  {
    id: 51,
    category: "Sculpture",
    image: img51,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Marcus Maxwel",
    subCategory: "Metal",
  },

  {
    id: 52,
    category: "Sculpture",
    image: img52,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Marcus Maxwel",
    subCategory: "Metal",
  },

  {
    id: 53,
    category: "Sculpture",
    image: img53,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Ojegoke Pelumi",
    subCategory: "Wood-work",
  },

  {
    id: 54,
    category: "Sculpture",
    image: img54,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Ojegoke Pelumi",
    subCategory: "Metal",
  },

  {
    id: 55,
    category: "Sculpture",
    image: img55,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Ojegoke Pelumi",
    subCategory: "Wood-work",
  },

  {
    id: 56,
    category: "Sculpture",
    image: img56,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Kenny Adamson",
    subCategory: "Wood-work",
  },

  {
    id: 57,
    category: "Sculpture",
    image: img57,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "Kenny Adamson",
    subCategory: "Wood-work",
  },

  {
    id: 58,
    category: "Sculpture",
    image: img58,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "B. Alvin",
    subCategory: "Metal",
  },

  {
    id: 59,
    category: "Sculpture",
    image: img59,
    title: "ADAO - Lorem ipsum dolor sit amet consectetur",
    author: "B. Alvin",
    subCategory: "Metal",
  },

  //  Paintings 2

  {
    id: 60,
    category: "Painting",
    image: img60,
    author: "Martha Ada Okechukwu",
    subCategory: "Stylized",
  },

  {
    id: 61,
    category: "Painting",
    image: img61,
    author: "Martha Ada Okechukwu",
    subCategory: "Landscape",
  },

  {
    id: 62,
    category: "Painting",
    image: img62,
    author: "Martha Ada Okechukwu",
    subCategory: "Stylized",
  },

  {
    id: 63,
    category: "Painting",
    image: img63,
    author: "Martha Ada Okechukwu",
    subCategory: "Stylized",
  },

  {
    id: 64,
    category: "Painting",
    image: img64,
    author: "Lasisi Babatunde",
    subCategory: "Stylized",
  },

  {
    id: 65,
    category: "Painting",
    image: img65,
    author: "Lasisi Babatunde",
    subCategory: "Stylized",
  },

  {
    id: 66,
    category: "Painting",
    image: img66,
    author: "Lasisi Babatunde",
    subCategory: "Stylized",
  },

  {
    id: 67,
    category: "Painting",
    image: img67,
    author: "Lasisi Babatunde",
    subCategory: "Stylized",
  },

  {
    id: 68,
    category: "Painting",
    image: img68,
    author: "Lasisi Babatunde",
    subCategory: "Stylized",
  },

  {
    id: 69,
    category: "Painting",
    image: img69,
    author: "Lasisi Babatunde",
    subCategory: "Stylized",
  },

  {
    id: 70,
    category: "Drawing",
    image: img70,
    author: "B. Alvin",
    subCategory: "Mixed Media",
  },

  {
    id: 71,
    category: "Drawing",
    image: img71,
    author: "B. Alvin",
    subCategory: "Mixed Media",
  },

  {
    id: 72,
    category: "Painting",
    image: img72,
    author: "Almat Adams",
    subCategory: "Figurative",
  },

  {
    id: 73,
    category: "Painting",
    image: img73,
    author: "Almat Adams",
    subCategory: "Figurative",
  },

  {
    id: 74,
    category: "Painting",
    image: img74,
    author: "Almat Adams",
    subCategory: "Figurative",
  },

  {
    id: 75,
    category: "Painting",
    image: img75,
    author: "Almat Adams",
    subCategory: "Figurative",
  },

  {
    id: 76,
    category: "Painting",
    image: img76,
    author: "Almat Adams",
    subCategory: "Figurative",
  },

  {
    id: 77,
    category: "Painting",
    image: img77,
    author: "Almat Adams",
    subCategory: "Figurative",
  },

  {
    id: 78,
    category: "Painting",
    image: img78,
    author: "Olabode Bankole",
    subCategory: "Satire",
  },

  {
    id: 79,
    category: "Painting",
    image: img79,
    author: "John Israel G.",
    subCategory: "Watercolor",
  },

  {
    id: 80,
    category: "Painting",
    image: img80,
    author: "John Israel G.",
    subCategory: "Realism",
  },

  {
    id: 81,
    category: "Painting",
    image: img81,
    author: "John Israel G.",
    subCategory: "Landscape",
  },

  {
    id: 82,
    category: "Painting",
    image: img82,
    author: "John Israel G.",
    subCategory: "Stylized",
  },

  {
    id: 83,
    category: "Painting",
    image: img83,
    author: "John Israel G.",
    subCategory: "Stylized",
  },

  {
    id: 84,
    category: "Painting",
    image: img84,
    author: "John Israel G.",
    subCategory: "Seascape",
  },

  {
    id: 85,
    category: "Painting",
    image: img85,
    author: "Olabode Bankole",
    subCategory: "Impressionism",
  },

  {
    id: 86,
    category: "Painting",
    image: img86,
    author: "Olabode Bankole",
    subCategory: "Impressionism",
  },

  {
    id: 87,
    category: "Painting",
    image: img87,
    author: "Olabode Bankole",
    subCategory: "Impressionism",
  },

  {
    id: 88,
    category: "Painting",
    image: img88,
    author: "John Israel G.",
    subCategory: "Seascape",
  },

  {
    id: 89,
    category: "Painting",
    image: img89,
    author: "John Israel G.",
    subCategory: "Impressionism",
  },

  //  Ceramics
  {
    id: 90,
    category: "Ceramics",
    image: img90,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 91,
    category: "Ceramics",
    image: img91,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 92,
    category: "Ceramics",
    image: img92,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 93,
    category: "Ceramics",
    image: img93,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 94,
    category: "Ceramics",
    image: img94,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 95,
    category: "Ceramics",
    image: img95,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 96,
    category: "Ceramics",
    image: img96,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 97,
    category: "Ceramics",
    image: img97,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 98,
    category: "Ceramics",
    image: img98,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 99,
    category: "Ceramics",
    image: img99,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 100,
    category: "Ceramics",
    image: img100,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 101,
    category: "Ceramics",
    image: img101,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 102,
    category: "Ceramics",
    image: img102,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 103,
    category: "Ceramics",
    image: img103,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 104,
    category: "Ceramics",
    image: img104,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 105,
    category: "Ceramics",
    image: img105,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 106,
    category: "Ceramics",
    image: img106,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 107,
    category: "Ceramics",
    image: img107,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 108,
    category: "Ceramics",
    image: img108,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 109,
    category: "Ceramics",
    image: img109,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 110,
    category: "Ceramics",
    image: img110,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 111,
    category: "Ceramics",
    image: img111,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 112,
    category: "Ceramics",
    image: img112,
    author: "Ojo Abiodun Enitan",
  },

  {
    id: 113,
    category: "Photography",
    image: img113,
    author: "Dipo Adebo",
  },

  {
    id: 114,
    category: "Photography",
    image: img114,
    author: "Dipo Adebo",
  },

  {
    id: 115,
    category: "Photography",
    image: img115,
    author: "Dipo Adebo",
  },

  {
    id: 116,
    category: "Photography",
    image: img116,
    author: "Dipo Adebo",
  },

  {
    id: 117,
    category: "Photography",
    image: img117,
    author: "Dipo Adebo",
  },

  {
    id: 118,
    category: "Photography",
    image: img118,
    author: "Dipo Adebo",
  },
];
